import { createSlice } from '@reduxjs/toolkit'
import { USER_TYPE } from "../../../../lib/constants"

const initialState = {
  prevNetworkKey:'',
  networkKey: '',
  networkInfo: {},
  users: [],
  userStats: {},
  topics: [],
  derivedNetworkInfo: {
    multipleUserTypes: true,
    userTypeLabels: ['Professional', 'Student']
  }
}

const admin_slice = createSlice({
  name: 'request',
  initialState: initialState,
  reducers: {
    clearData (state, action) {
      state.prevNetworkKey = '';
      state.users = [];
      state.userStats = {};
      state.topics = [];
      return state;
    },
    setTopics(state, action) {
      state.topics = action.payload;
      return state;
    },
    setUsers(state, action) {
      state.users = action.payload;

      // Process users
      const localUserStats = {
        total: action.payload.length,
        [USER_TYPE.NORMAL]: 0,
        [USER_TYPE.STUDENT]: 0
      };

      action.payload.forEach((user) => {
        if (user.Type == USER_TYPE.NORMAL) {
          localUserStats[USER_TYPE.NORMAL]++;
        } else {
          localUserStats[USER_TYPE.STUDENT]++;
        }
      })
      state.userStats = localUserStats;
      return state;
    },
    setNetworkKey(state, action) {
      state.networkKey = action.payload;
      return state;
    },
    // TODO: not used?
    processNetworkInfo(state, action) {
      state.derivedNetworkInfo.multipleUserTypes = action.payload.DefaultUserType == USER_TYPE.ANY;
      state.derivedNetworkInfo.userTypeLabels = action.payload.DefaultUserType == USER_TYPE.ANY? ['Professional', 'Student'] : ['User'];
      return state;
    },
    setPrevNetworkKey(state, action) {
      state.prevNetworkKey = action.payload;
      return state;
    }
  },
});

export const { setUsers, processNetworkInfo, setTopics, setPrevNetworkKey, clearData, setNetworkKey} = admin_slice.actions
export default admin_slice.reducer;

