import { makeStyles, } from "@material-ui/core"
import { PURPLE } from "../lib/constants"

export default makeStyles(theme => ({
  boldText: {
    fontWeight: 'bold'
  },
  purpleGradient: {
    backgroundImage: 'linear-gradient(-90deg, #673AB7 53%, #794CC9 82%)'
  },
  textWhite: {
    color: 'white',
    borderColor: 'white'
  },
  textPurple: {
    color: PURPLE,
    borderColor: PURPLE
  },
  innerPadding: {
    padding: theme.spacing(2)
  },
  containerPadding: {
    padding: theme.spacing(0, 1)
  },
  denseInnerPadding: {
    padding: theme.spacing(1)
  },
  fullWidth: {
    width: '100%'
  },
  bottomMargin: {
    marginBottom: theme.spacing(2)
  },

  centerElements: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  centerColumnElements: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column'
  },
  centerText: {
      textAlign: 'center'
  },

  xsHideBorderRadius: {
    [theme.breakpoints.down('xs')]: {
      borderRadius: 0
    }
  },

  bottomNav: {
    position: 'fixed',
    left: 0,
    bottom: 0,
    width: '100%'
  },

  settingsAvatar: {
    margin: '0 auto',
    width: theme.spacing(20),
    height: theme.spacing(20),
  },

  paperListItemParent: {
    padding: 0
  },
  paperListItem: {
    padding: theme.spacing(2),
    width: '100%',
    display: 'flex',
    position: 'relative',
    boxSizing: 'border-box',
    textAlign: 'left',
    alignItems: 'center',
    paddingTop: '8px',
    paddingBottom: '8px',
    justifyContent: 'flex-start',
    textDecoration: 'none'
  },

  onlyActionButton: {
    width: '75%',
    height: '48px'
  }
}));

