import React, {useState, useEffect} from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import {
  Backdrop,
  Box,
  CircularProgress,
  ThemeProvider,
  Typography
} from "@material-ui/core"
import clsx from "clsx"
import { makeStyles } from "@material-ui/core/styles"
import SharedStyles from "../components/shared_styles"
import theme from '../lib/theme'
import { PURPLE } from "../lib/constants"
import {navigate} from "@reach/router"

const useStyles = makeStyles((theme) => ({
  backdrop: {
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    top: 0,
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: PURPLE,
    color: '#fff',
  },
  logo: {
    width: '300px'
  }
}));

const NotFoundPage = () => {
  const classes = useStyles();
  const styles = SharedStyles();

  useEffect(() => {
    setTimeout(()=> {
      alert("The page you're trying to access doesn't exist. Redirecting you home.");
      window.location = '/';
    }, 4000)
  }, [])

  return (
    <ThemeProvider theme={theme} key="loading">
      <Box className={clsx([classes.backdrop, styles.centerColumnElements])}>
        <Typography>Connecting to</Typography>
        <Box>
          <svg width="451px" height="101px" viewBox="0 0 451 101" version="1.1" xmlns="http://www.w3.org/2000/svg"
               className={classes.logo}>
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
              <g id="Group">
                <rect id="Rectangle" fill="#673AB7" x="0" y="0" width="451" height="101"></rect>
                <text id="Greenwoodx" fontFamily="Avenir-Heavy, Avenir" fontSize="65" fontWeight="600" fill="#FFFFFF">
                  <tspan x="28.745" y="69">Greenwoodx</tspan>
                </text>
              </g>
            </g>
          </svg>
        </Box>
        <CircularProgress color="inherit"/>
      </Box>
    </ThemeProvider>
  )
}

export default NotFoundPage
