import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  topics: [],
  requestTopic: null,
  addedNote: '',
  selectedTimeslot: null,

  // Keys are either hasAccount=True if the user is authenticated and
  // (firstName, lastName, email, phone) if not.
  contactInfo: {
    hasAccount: false,
    firstName: '',
    lastName: '',
    email: '',
    phone: ''
  },
}

const request_slice = createSlice({
  name: 'request',
  initialState: initialState,
  reducers: {
    saveTopics(state, action) {
      state.topics = action.payload;
      return state;
    },
    setRequestTopic(state, action) {
      state.requestTopic = action.payload
      return state;
    },
    setSelectedTimeslot(state, action) {
      state.selectedTimeslot = action.payload;
      return state;
    },
    setContactInfo(state, action) {
      state.contactInfo[action.payload.key] = action.payload.value;
      return state;
    },
    setAddedNote(state, action) {
      state.addedNote = action.payload;
      return state;
    }
  },
});

export const { setRequestTopic, setSelectedTimeslot, setContactInfo, setAddedNote, saveTopics } = request_slice.actions
export default request_slice.reducer;

