/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { Helmet } from "react-helmet"
import { useSelector, useDispatch, Provider } from "react-redux"
import {setSnackbar} from "./app/features/system/system_slice"

import Header from "./header"
import "typeface-nunito-sans"
import {
  Box, Container, CssBaseline, makeStyles, Snackbar,
  ThemeProvider,
} from "@material-ui/core"
import theme from '../lib/theme'
import ReduxStore from "./app/store"

const useStyles = makeStyles(theme => ({
  footer: {
    /*position: 'absolute',
    bottom: 0,*/
    width: '100%',
    marginTop: theme.spacing(10),
    borderTop: '1px solid',
    borderTopColor: theme.palette.divider,
    paddingTop: theme.spacing(3),
  }
}));

const Layout = ({ children, showFooter = true , showHeader = true}) => {
  const classes = useStyles();

  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <ThemeProvider theme={theme}>
      <Provider store={ReduxStore}>
        <CssBaseline/>
        <Helmet>
          <meta name = "viewport" content = "user-scalable=no, width=device-width" />
        </Helmet>
        {showHeader && <Header />}
        {children}
        {showFooter &&
          <footer className={classes.footer}>
            <Container maxWidth="md" align="center">
              <a href="https://forms.gle/s55aY51VhN82gFPZ8">
                Contact Us
              </a>
              <br/>
              © {new Date().getFullYear()} Greenwoodx
            </Container>
          </footer>
        }
      </Provider>
    </ThemeProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
