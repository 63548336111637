import { combineReducers } from "redux";
import requestReducer from '../features/request/request_slice'
import systemReducer from '../features/system/system_slice'
import adminReducer from '../features/admin/admin_slice'

export default combineReducers({
  request: requestReducer,
  system: systemReducer,
  admin: adminReducer
});
