import PropTypes from "prop-types"
import React from "react"
import { AppBar, Button, Grid, Hidden, makeStyles, Toolbar, Typography, Link } from "@material-ui/core"
import { navigate } from "@reach/router"

const useStyles = makeStyles(theme => ({
  limitedRelease: {
    display:'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    margin: theme.spacing(3)
  },
  appBarTitle: {
    flexGrow: 1
  },
  homeButton: {

  },
  logo: {
    width: '140px'
  }
}));

const Header = ({ siteTitle }) => {
  const classes = useStyles();
  return (
    <AppBar position="static" color="transparent" elevation={0} className={classes.appBar}>
      <Toolbar variant="dense">
        <Typography variant="caption" color="primary" className={classes.appBarTitle}>
          <Link
            className={classes.homeButton}
            color="primary"

            onClick={()=>navigate('/')}
          >
            <img src="https://greenwoodx.s3.us-east-2.amazonaws.com/purple_logo.svg" className={classes.logo}/>
          </Link>
        </Typography>
      </Toolbar>
    </AppBar>
  );
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
